import React from 'react';

import Header from '../Header';
import Footer from '../Footer';

import Hero from './Hero';
import AboutFilm from './AboutFilm';
import Tickets from './Tickets';
import Conditions from './Conditions';
import HowToGet from './HowToGet';
import Film from './Film';

export default () => (
  <>
    <Header />
    <Hero />
    <AboutFilm />
    <Tickets />
    <Conditions />
    <HowToGet />
    <Film />
    <Footer />
  </>
);
