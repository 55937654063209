import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Route from './Route';

import Home from './Home';
import FAQ from './FAQ';
import About from './About';
import Support from './Support';

export default () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/faq" exact component={FAQ} />
      <Route path="/about" exact component={About} />
      <Route path="/support" exact component={Support} />
    </Switch>
  </BrowserRouter>
);
