import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';
import axios from 'axios';

import { Input, Button } from '../../UI';

import warrantySVG from './locked.svg';
import Payment from './Payment';

const Wrapper = styled.div`
  margin-top: 30px;

  @media screen and (min-width: 1200px) {
    margin-top: 50px;
  }
`;

const PaymentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    padding-left: 40px;
  }
`;

const Warranty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;

  @media screen and (min-width: 1200px) {
    margin-top: 0;
    margin-left: 15px;
    margin-bottom: 0;
    flex-direction: row;
  }
`;

const WarrantyIcon = styled.img`
  max-width: 12px;
  margin-bottom: 5px;

  @media screen and (min-width: 1200px) {
    max-width: 100%;
    margin-right: 10px;
    margin-bottom: 0;
  }
`;

const WarrantyDescription = styled.p`
  margin: 0;
  color: #c3c3c3;
  font-size: 8px;
  font-weight: 500;

  @media screen and (min-width: 1200px) {
    font-size: 12px;
  }
`;

const ButtonAndWarranty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;

const ButtonWrapper = styled.div``;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default class FormComponent extends React.Component {
  componentDidMount() {
    // this.props.setRefs(this.name, this.secondName, this.email, this.phone);
  }

  validateEmail = email => {
    const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  validatePhone = phone => {
    const re = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/;
    return re.test(String(phone).replace(/\D/g, ''));
  };

  handleSubmit = e => {
    e.preventDefault();

    const name = this.name.input.value;
    const secondName = this.secondName.input.value;
    const email = this.email.input.value;
    const phone = this.phone.input.value;

    if (!name) {
      alert('Заполните поле Имя');
      return false;
    }

    if (!secondName) {
      alert('Заполните поле Фамилия');
      return false;
    }

    if (!email || !this.validateEmail(email)) {
      alert('Заполните поле Email');
      return false;
    }

    if (!phone || !this.validatePhone(phone)) {
      alert('Заполните поле Телефона');
      return false;
    }

    axios
      .put('https://www.hermitageshop.ru/api/v2/vrorder', {
        name,
        secondname: secondName,
        email,
        phone,
        standartpersons: this.props.currentNum,
        benefitpersons: 0
      })
      .then(res => {
        if (res.data.result === true && res.data.linktopay) {
          window.location.replace(res.data.linktopay);
        }
      });
  };

  render() {
    return (
      <Wrapper>
        <Row>
          <Col xs={12} lg={5}>
            <Form onSubmit={this.handleSubmit}>
              <Input label="Имя*" ref={name => (this.name = name)} />

              <Input
                label="Фамилия*"
                ref={secondName => (this.secondName = secondName)}
              />

              <Input
                label="E-mail*"
                ref={email => (this.email = email)}
                type="email"
              />

              <Input label="Телефон*" ref={phone => (this.phone = phone)} />
            </Form>
          </Col>

          <Col xs={12} lg={6}>
            <PaymentWrapper>
              <Payment />

              <ButtonAndWarranty>
                <ButtonWrapper>
                  <Button onClick={this.handleSubmit}>Купить билет</Button>
                </ButtonWrapper>

                <Warranty>
                  <WarrantyIcon src={warrantySVG} />

                  <WarrantyDescription>
                    Мы гарантируем безопасность платежей
                  </WarrantyDescription>
                </Warranty>
              </ButtonAndWarranty>
            </PaymentWrapper>
          </Col>
        </Row>
      </Wrapper>
    );
  }
}
