import React from 'react';
import styled from 'styled-components';
import { Link as RLink } from 'react-router-dom';

const Link = styled(RLink)``;

export default (props) => (
  <>
    <Link {...props}>{props.children}</Link>
  </>
);
