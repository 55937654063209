import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { Link as UILink } from '../UI';

import vrLogo from './vrLogo.svg';
import hermitageLogo from './hermitageLogo.svg';

import ModalPrivacy from '../Modals/Privacy';

const Wrapper = styled.footer`
  background-color: #f5f5f5;
  border-top: 1px solid #d5d5d5;
`;

const VRLogo = styled.img`
  width: 40px;

  @media screen and (min-width: 1200px) {
    width: 54px;
  }
`;

const MainInfo = styled.div`
  padding: 40px 0;
  padding-bottom: 0;

  @media screen and (min-width: 1200px) {
    padding: 70px 0;
  }
`;

const WrapperLogoAndDescription = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 20px;
  border-bottom: 1px solid #d5d5d5;

  br {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    border: none;
    padding-bottom: 60px;
  }
`;

const Description = styled.p`
  margin: 0;
  color: #858688;
  font-size: 12px;
  font-weight: 500;
  margin-left: 20px;

  @media screen and (min-width: 1200px) {
    font-size: 16px;
  }
`;

const Copyright = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  color: #7d7d7d;
  margin-top: 50px;
  display: none;
  width: 100%;

  @media screen and (min-width: 1200px) {
    display: initial;
    font-size: 16px;
  }
`;

const CopyrightMobile = styled(Copyright)`
  display: block;
  margin-top: 0;
  padding: 0;
  text-align: center;
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
  padding: 20px 0;

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const Link = styled(UILink)`
  color: #7d7d7d;
  text-decoration: none;
  font-size: 12px;
  font-weight: 700;
  transition: all 0.18s;

  &:hover {
    opacity: 0.7;
  }

  @media screen and (min-width: 1200px) {
    font-size: 16px;
  }
`;

const LinkOriginal = styled.a`
  color: #7d7d7d;
  text-decoration: none;
  font-size: 12px;
  font-weight: 700;
  transition: all 0.18s;

  &:hover {
    opacity: 0.7;
  }

  @media screen and (min-width: 1200px) {
    font-size: 16px;
  }
`;

const Point = styled.li`
  margin-bottom: 20px;

  @media screen and (min-width: 1200px) {
    margin-bottom: 12px;
  }
`;

const Navigation = styled.nav`
  list-style-type: none;
  text-align: center;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (min-width: 1200px) {
    margin-right: 60px;
    text-align: left;
  }

  &:last-child ${Point}:last-child {
    margin-bottom: 0;
  }
`;

const Navigations = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 30px 0;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    padding: 70px 0;
  }
`;

const HermitageLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding: 40px 0;

  &:before {
    content: '';
    position: absolute;
    left: -25px;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: #d5d5d5;
    display: none;
  }

  @media screen and (min-width: 1200px) {
    padding: 70px 0;

    &:after {
      display: block;
    }
  }
`;

const HermitageLogo = styled.img`
  max-width: 62px;
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    max-width: initial;
    margin: initial;
  }
`;

const HermitageDescription = styled.p`
  margin: 0;
  font-size: 10px;
  font-weight: 600;
  color: #656565;
  text-align: center;
  margin-top: 10px;

  @media screen and (min-width: 1200px) {
    margin-top: 15px;
  }
`;

const LinkModal = styled.span`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
  const [isOpenPrivacy, setOpenPrivacy] = useState(false);
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Wrapper>
        <Grid>
          <Row>
            <Col xs={12} xl={6}>
              <MainInfo>
                <WrapperLogoAndDescription>
                  <VRLogo src={vrLogo} />

                  <Description>
                    Фильм произведен компанией «Артеком» совместно со студиями
                    Super8.pro и Videofabrika.ru.
                  </Description>
                </WrapperLogoAndDescription>

                <Copyright>
                  © {currentYear} ООО "Артеком" |{' '}
                  <LinkModal onClick={() => setOpenPrivacy(true)}>
                    Политика и соглашения
                  </LinkModal>
                </Copyright>
              </MainInfo>
            </Col>

            <Col xs={12} xl={4}>
              <Navigations>
                <Navigation>
                  <Point>
                    <Link to="/faq" href="/faq">
                      Общие вопросы
                    </Link>
                  </Point>

                  {/* <Point>
                    <Link to="/support" href="/support">
                      Поддержка
                    </Link>
                  </Point> */}

                  <Point>
                    <Link to="/about" href="/about">
                      О нас
                    </Link>
                  </Point>
                </Navigation>

                <Navigation>
                  <Point>
                    <LinkOriginal
                      href="https://www.hermitageshop.ru/"
                      target="_blank"
                    >
                      Hermitage Shop
                    </LinkOriginal>
                  </Point>

                  <Point>
                    <LinkOriginal
                      href="https://vk.com/hermitagevr"
                      target="_blank"
                    >
                      VK
                    </LinkOriginal>
                  </Point>

                  <Point>
                    <LinkOriginal
                      href=" https://facebook.com/hermitagevr"
                      target="_blank"
                    >
                      Facebook
                    </LinkOriginal>
                  </Point>

                  <Point>
                    <LinkOriginal
                      href="https://instagram.com/hermitage.vr"
                      target="_blank"
                    >
                      Instagram
                    </LinkOriginal>
                  </Point>
                </Navigation>
              </Navigations>
            </Col>

            <Col xs={12} className="hidden-xl">
              <CopyrightMobile>
                © 2019 Museum On Line |{' '}
                <LinkModal onClick={() => setOpenPrivacy(true)}>
                  Политика и соглашения
                </LinkModal>
              </CopyrightMobile>
            </Col>

            <Col xs={12} xl={2}>
              <HermitageLogoWrapper>
                <HermitageLogo src={hermitageLogo} />

                <HermitageDescription>
                  Официальный партнер
                  <br />
                  Государственный Эрмитаж
                </HermitageDescription>
              </HermitageLogoWrapper>
            </Col>
          </Row>
        </Grid>
      </Wrapper>

      <ModalPrivacy
        isOpen={isOpenPrivacy}
        onClose={() => setOpenPrivacy(false)}
      />
    </>
  );
};

export default Footer;
