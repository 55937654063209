import React from 'react';
import styled from 'styled-components';
import { Grid } from 'react-flexbox-grid';

import Header from '../Header';
import Footer from '../Footer';

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 100px;

  @media screen and (min-width: 1200px) {
    margin-top: 50px;
    margin-bottom: 150px;
  }
`;

const Title = styled.h1`
  margin: 0;
  margin-bottom: 30px;
  font-size: 23px;

  @media screen and (min-width: 1200px) {
    margin-bottom: 50px;
    font-size: 30px;
  }
`;

const Content = styled.div`
  font-size: 13px;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }
`;

export default () => (
  <>
    <Header />

    <Wrapper>
      <Grid>
        <Title>Общие вопросы</Title>

        <Content>
          1) Вы можете приобрести билет заранее, оплатив его картой. Электронный
          билет дает право посетить мероприятие вне очереди.
          <br />
          <br />
          2) После оплаты билета, Вам на почту придет квитанция об оплате и
          электронный билет.
          <br />
          <br />
          3) Билет можно предъявить администратору кинотеатра в распечатанном
          или электронном виде.
          <br />
          <br />
          4) При покупке билетов для нескольких человек, вам придет билет на
          каждого из посетителей. Билеты по одному заказу должны быть
          использованы одновременно. Если вы хотите, чтобы посетители пришли по
          раздельности, - сделайте отдельные заказы.
          <br />
          <br />
          5) Билет действует 180 дней с момента покупки.
          <br />
          <br />
          6) Билет можно отменить в течение 180 дней с момента покупки, для
          этого свяжитесь с нашей службой поддержки по телефону или электронной
          почте info@hermitageshop.com, указав номер заказа и имя заказчика. При
          отмене билета сбор по обработке заказа 50 руб. за каждый проданный
          билет не возвращается. Возврат средств производится обратной
          транзакцией на карту, которая была использована при оплате.
          <br />
          <br />
          7) Частичная отмена заказа невозможна. Заказ отменяется только
          полностью.
          <br />
          <br />
          8) Действует единая цена для всех категорий граждан. Льготы на билеты
          не распространяются.
          <br />
          <br />
          9) Если у Вас остались вопросы, пожалуйста, свяжитесь с нами по
          телефону: +7 812 429-74-07 или по электронной почте
          info@hermitageshop.com
          <br />
          <br />
          <br />
          <br />
          Просмотр фильма проходит в здании Главного Штаба на Дворцовой площади.
          VR кинотеатр расположился на территории «Общественного форума» в
          четвертом дворе здания Главного Штаба.
          <br />
          <br />
          Стоимость билета - 350 рублей
          <br />
          <br />
          График работы:
          <br />
          Вторник, четверг, суббота и воскресенье - с 10:30 до 18:00, среда и{' '}
          <br />
          пятница с 12:00 до 21:00.
          <br />
          (понедельник - выходной день в Государственном Эрмитаже)
          <br />
          <br />
          Для детей старше 8 лет.
          <br />
          <br />
          Служба поддержки:
          <br />
          <br />
          +7 (812) 429-74-07 <br />
          info@hermitageshop.com
        </Content>
      </Grid>
    </Wrapper>

    <Footer />
  </>
);
