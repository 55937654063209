import React from 'react';
import styled from 'styled-components';
import { Grid, Row } from 'react-flexbox-grid';

import Message from './Message';

const Wrapper = styled.div`
  margin-top: 60px;

  @media screen and (min-width: 1200px) {
    margin-top: 100px;
  }
`;

const Title = styled.h2`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  margin-bottom: 40px;

  @media screen and (min-width: 1200px) {
    font-size: 30px;
    font-weight: 600;
    max-width: 100%;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1200px) {
    flex-wrap: wrap;
    align-items: initial;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const MoreInformation = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  padding: 0 15px;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    padding: 0;
  }
`;

const Link = styled.a`
  color: #3797f0;
  text-decoration: none;
`;

export default () => (
  <Wrapper>
    <Grid>
      <Row>
        <Title>Условия использования и возврата</Title>

        <List>
          <Message>
            Вы можете приобрести билет заранее, оплатив
            <br />
            его картой. Электронный билет дает право
            <br />
            посетить мероприятие вне очереди.
          </Message>

          <Message>
            После оплаты билета, вам на почту придет
            <br />
            квитанция об оплате и электронный билет.
          </Message>

          <Message>
            Билет можно предъявить администратору
            <br />
            кинотеатра в распечатанном или электронном
            <br />
            виде.
          </Message>

          <Message>
            Билет можно отменить в течение 180 дней с момента покупки, для этого
            <br />
            свяжитесь с нашей службой поддержки по телефону или электронной
            почте
            <br />
            info@hermitageshop.com, указав номер заказа и имя заказчика. При
            отмене
            <br />
            заказа с каждого билета удерживается стоимость обработки заказа - 50
            руб.
            <br />
            Возврат средств производится обратной транзакцией на
            <br />
            карту, которая была использована при оплате.
          </Message>

          <Message>
            При покупке билетов для нескольких человек, вам придет билет на
            <br />
            каждого из посетителей. Билеты по одному заказу должны быть
            использованы
            <br />
            одновременно. Если вы хотите, чтобы посетители пришли по
            раздельности,
            <br />- сделайте отдельные заказы.
          </Message>

          <Message>Билет действует 180 дней с момента покупки.</Message>

          <Message>
            Частичная отмена заказа невозможна.
            <br />
            Заказ отменяется только полностью.
          </Message>

          <Message>
            Действует единая цена для всех категорий
            <br />
            граждан. Льготы на билеты не
            <br />
            распространяются.
          </Message>
        </List>

        <MoreInformation>
          Если у вас остались вопросы, пожалуйста, свяжитесь с нами{' '}
          <Link href="tel:+78124297407">по телефону: +7 (812) 429-74-07</Link>{' '}
          или по электронной почте{' '}
          <Link href="mailto:info@hermitageshop.com">
            info@hermitageshop.com
          </Link>
        </MoreInformation>
      </Row>
    </Grid>
  </Wrapper>
);
