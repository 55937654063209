import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { MiniCheckbox as UIMiniCheckbox, Button } from '../../UI';

import ModalPrivacy from '../../Modals/Privacy';

import warrantySVG from './locked.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  @media screen and (min-width: 1200px) {
    margin-top: 80px;
  }
`;

const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;
`;

const MiniCheckbox = styled(UIMiniCheckbox)`
  color: #fff;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;

  @media screen and (min-width: 1200px) {
    margin-top: 30px;
  }
`;

const Warranty = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const WarrantyIcon = styled.img`
  max-width: 12px;

  @media screen and (min-width: 1200px) {
    max-width: 100%;
  }
`;

const WarrantyDescription = styled.p`
  margin: 0;
  color: #fff;
  font-size: 8px;
  font-weight: 500;
  margin-top: 5px;

  @media screen and (min-width: 1200px) {
    font-size: 12px;
  }
`;

const LinkModal = styled.span`
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export default function Actions({
  isReadPolicy,
  setReadPolicy,
  isReceiveNews,
  setReceiveNews,
  isSending,
  setSending,
  isLoadedData
}) {
  const [isOpenModal, setOpenModal] = useState(false);

  return (
    <>
      <Wrapper>
        <Checkboxes>
          <MiniCheckbox
            isActive={isReadPolicy}
            onClick={() => setReadPolicy(!isReadPolicy)}
          >
            Я прочел (прочла) условия{' '}
            <LinkModal onClick={() => setOpenModal(true)}>
              Политики Конфиденциальности
            </LinkModal>
          </MiniCheckbox>

          <MiniCheckbox
            isActive={isReceiveNews}
            onClick={() => setReceiveNews(!isReceiveNews)}
          >
            Получать красивую рассылку о новых поступлениях и скидках
            <br />
            Эрмитажного магазина
          </MiniCheckbox>
        </Checkboxes>

        <ButtonWrapper>
          <Button
            disabled={isSending || !isLoadedData}
            onClick={() => setSending(true)}
          >
            {isSending ? 'Оформляем...' : 'Купить билет'}
          </Button>
        </ButtonWrapper>

        <Warranty>
          <WarrantyIcon src={warrantySVG} />

          <WarrantyDescription>
            Мы гарантируем безопасность платежей
          </WarrantyDescription>
        </Warranty>
      </Wrapper>

      <ModalPrivacy isOpen={isOpenModal} onClose={() => setOpenModal(false)} />
    </>
  );
}
