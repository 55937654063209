import React from 'react';
import styled from 'styled-components';

import photo1 from './photos/1@2x.jpg';
import photo2 from './photos/2@2x.jpg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  @media screen and (min-width: 1200px) {
    margin-top: 35px;
  }
`;

const Slide = styled.div`
  max-width: 182px;
  margin-right: 30px;
  position: relative;
  margin-right: 50px;

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 10px;
    top: 7px;
    width: 100%;
    height: 100%;
    border: 3px solid #3797f0;
    box-sizing: border-box;
  }

  &:first-child {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    max-width: 370px;

    &:first-child {
      display: block;
    }

    &:after {
      left: 20px;
      top: 20px;
      border: 5px solid #3797f0;
    }
  }
`;

const Photo = styled.img`
  max-width: 100%;
  position: relative;
  z-index: 2;
`;

const Slider = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Description = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  margin-top: 40px;
  text-align: center;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    text-align: left;
  }
`;

const Link = styled.a`
  color: #3797f0;
  position: relative;
  text-decoration: none;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 1px;
    background-color: #3797f0;
    transition: all 0.1s;
  }

  &:hover {
    &:after {
      opacity: 0;
    }
  }
`;

export default () => (
  <Wrapper>
    <Slider>
      <Slide>
        <Photo src={photo1} />
      </Slide>

      <Slide>
        <Photo src={photo2} />
      </Slide>
    </Slider>

    <Description>
      Больше фотографий в{' '}
      <Link href="https://instagram.com/hermitage.vr" target="_blank">
        нашем Instagram
      </Link>
    </Description>
  </Wrapper>
);
