import React from 'react';

import { InputGroup, InputHolder, InputLabel as Label } from './styled';

export default class Input extends React.Component {
  state = {
    isFocus: !!this.props.defaultValue
  };

  componentDidMount() {
    setTimeout(() => {
      if (
        !this.props.readonly &&
        this.input &&
        this.input.hasAttribute('readonly')
      ) {
        this.input.removeAttribute('readonly');
      }
    }, 500);
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      if (!this.state.isFocus) {
        this.setState({ isFocus: true });
      }
    }
  }

  setFocus = isFocus => {
    this.setState({ isFocus });

    if (isFocus) {
      this.input.focus();
    }
  };

  getValue = () => {
    if (this.props.defaultValue) {
      return this.props.defaultValue;
    }

    return '';
  };

  render() {
    return (
      <InputGroup onClick={() => this.setFocus(true)}>
        <Label isFocus={this.state.isFocus} alignLabel={this.props.alignLabel}>
          {this.props.label}
        </Label>

        <InputHolder
          ref={input => {
            this.input = input;
          }}
          {...this.props}
          onBlur={e => {
            if (this.input.value === '') {
              this.setFocus(false);
            }

            if (this.props.onBlur) {
              this.props.onBlur(e.target);
            }
          }}
          onChange={({ target: value }) =>
            !this.state.isFocus
              ? this.setFocus(true)
              : this.props.onChange
              ? this.props.onChange(value)
              : ''
          }
          onPaste={e =>
            !this.state.isFocus
              ? this.setFocus(true)
              : this.props.onChange
              ? this.props.onChange(e.clipboardData.getData('text/plain'))
              : ''
          }
          isFocus={this.state.isFocus}
          type={this.props.type ? this.props.type : 'text'}
          readOnly
          alignLabel={this.props.alignLabel}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />
      </InputGroup>
    );
  }
}
