import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  border: none;
  outline: none;
  background-color: ${({ disabled }) => (disabled ? '#d9d9d9' : '#e71d85')};
  width: 18px;
  height: 18px;
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => (theme === 'black' ? '#000' : '#FFF')};
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  line-height: 1;
  text-selection: none;
  transition: all 0.18s;

  @media screen and (min-width: 1200px) {
    width: 28px;
    height: 28px;
    font-size: 20px;
  }
`;

const Minus = styled(Button)``;

const Plus = styled(Button)``;

const Num = styled.div`
  font-size: 26px;
  font-weight: 300;
  color: ${({ theme }) => (theme === 'black' ? '#000' : '#FFF')};
  margin: 0 15px;

  @media screen and (min-width: 1200px) {
    font-size: 50px;
  }
`;

export default function Quantity({
  onChangeNum,
  currentNum,
  isVisible,
  theme
}) {
  return (
    <Wrapper>
      <Minus
        isVisible={isVisible}
        disabled={currentNum === 1}
        onClick={() => currentNum > 1 && onChangeNum(currentNum - 1)}
        theme={theme}
      >
        -
      </Minus>

      <Num theme={theme}>{currentNum}</Num>

      <Plus
        theme={theme}
        isVisible={isVisible}
        disabled={currentNum === 99}
        onClick={() => currentNum < 99 && onChangeNum(currentNum + 1)}
      >
        +
      </Plus>
    </Wrapper>
  );
}
