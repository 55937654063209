import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid } from 'react-flexbox-grid';
import { scroller } from 'react-scroll';

import { Button } from '../../UI';

import preview from './preview@2x.jpg';
import previewMobile from './previewMobile@2x.jpg';

import ModalTrailer from '../../Modals/Trailer';

const Wrapper = styled.div`
  background-color: #f5f5f5;
  padding-top: 30px;
  border-top: 1px solid #d5d5d5;

  @media screen and (min-width: 1200px) {
    padding-top: 140px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;

const Title = styled.h2`
  font-size: 26px;
  font-weight: 700;
  margin: 0;
  flex-basis: 40%;
  margin-bottom: 20px;

  @media screen and (min-width: 1200px) {
    font-size: 50px;
    font-weight: 900;
    margin-bottom: 0;
    line-height: 1;
  }
`;

const Information = styled.div`
  flex-basis: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 0 15px;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    padding: 0;
  }
`;

const Description = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 30px;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    margin-bottom: 0;
  }
`;

const Points = styled.div`
  width: 100%;

  @media screen and (min-width: 1200px) {
    width: auto;
  }
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;
`;

const PointTitle = styled.h4`
  font-weight: 700;
  font-size: 12px;
  margin: 0;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }
`;

const PointDescription = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  margin-top: 5px;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }
`;

const VideoPreview = styled.div`
  height: 280px;
  width: 280px;
  background-image: url(${previewMobile});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  position: relative;
  z-index: 2;
  cursor: pointer;

  @media screen and (min-width: 1200px) {
    background-image: url(${preview});
    height: 578px;
    width: 1168px;
    margin-top: 100px;
  }
`;

const Play = styled.div`
  width: 50px;
  height: 50px;
  background-color: #3797f0;
  border-radius: 100%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: calc(50% - 5px);
    top: calc(50% - 13px);
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 16px solid #fff;
  }

  @media screen and (min-width: 1200px) {
    width: 78px;
    height: 78px;

    &:after {
      border-top: 21px solid transparent;
      border-bottom: 21px solid transparent;
      border-left: 28px solid #fff;
      left: calc(50% - 9px);
      top: calc(50% - 22px);
    }
  }
`;

const PreviewWrapper = styled.div`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    right: 0;
    width: 95%;
    height: 118px;
    top: 45%;
    top: calc(50% - 59px);
    width: 100%;
    background-color: #3797f0;
  }

  @media screen and (min-width: 1200px) {
    &:after {
      width: calc(100% - 50px);
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 1200px) {
    margin-top: 80px;
    padding-bottom: 100px;
  }
`;

// https://www.youtube.com/watch?v=-GwQBRTgvEs

const Film = () => {
  const [isOpenTrailer, setOpenTrailer] = useState(false);

  return (
    <>
      <Wrapper>
        <Grid>
          <Header>
            <Title>VR-фильм</Title>

            <Information>
              <Description>
                При помощи новейших технологий, объединивших виртуальную
                реальность и кино, фильм в формате виртуальной реальности
                (Virtual Reality) создает иллюзию присутствия зрителя в реальном
                пространстве и позволяет ему стать участником событий с помощью
                специальных очков.
                <br />
                <br />
                Фильм был представлен на Санкт-Петербургском международном
                культурном форуме в 2017 году, принял участие в 71-м Каннском
                кинофестивале, демонстрировался в Москве и по всей России.
              </Description>

              {/* <Points>
                <Point>
                  <PointTitle>13+</PointTitle>
                  <PointDescription>19 минут</PointDescription>
                </Point>
              </Points> */}
            </Information>
          </Header>
        </Grid>

        <PreviewWrapper id="film">
          <VideoPreview onClick={() => setOpenTrailer(true)}>
            <Play />
          </VideoPreview>
        </PreviewWrapper>

        <ButtonWrapper>
          <Button
            onClick={() =>
              scroller.scrollTo('buy', {
                duration: 500,
                smooth: true
              })
            }
          >
            Купить билет
          </Button>
        </ButtonWrapper>
      </Wrapper>

      <ModalTrailer
        isOpen={isOpenTrailer}
        onClose={() => setOpenTrailer(false)}
      />
    </>
  );
};

export default Film;
