import styled from 'styled-components/macro';

export const InputLabel = styled.label`
  font-size: ${props => (props.isFocus ? '9px' : '12px')};
  font-weight: ${props => (props.isFocus ? '600' : '500')};
  color: ${props => (props.isFocus ? '#000000' : '#656565')};
  position: absolute;
  top: ${props => (props.isFocus ? '-5px' : '11px')};
  cursor: text;
  transition: all 0.18s;
  text-align: ${props => (props.alignLabel ? props.alignLabel : 'left')};
  width: 100%;

  @media screen and (min-width: 1200px) {
    font-size: ${props => (props.isFocus ? '10px' : '15px')};
  }
`;

export const InputHolder = styled.input`
  font-size: 12px;
  font-weight: 500;
  border: none;
  border-bottom: 1px solid ${props => (props.isFocus ? '#e71d85' : '#cdcdcd')};
  outline: none;
  padding: 10px 0;
  transition: all 0.5s;
  background: transparent;
  text-align: ${props => (props.alignLabel ? props.alignLabel : 'left')};
  cursor: initial;

  &:focus {
    border-color: #e71d85;
  }

  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;

  @media screen and (min-width: 1200px) {
    margin-bottom: 30px;
  }
`;
