import React from 'react';
import styled from 'styled-components';
import { Grid, Row } from 'react-flexbox-grid';

import { scroller } from 'react-scroll';

import { Button } from '../UI';

import heroImg from './photos/hero@2x.jpg';
import heroImgMobile from './photos/heroMobile@2x.jpg';

const Wrapper = styled.div`
  min-height: 508px;
  background-image: url(${heroImgMobile});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: flex-end;
  position: relative;

  @media screen and (min-width: 1200px) {
    background-image: url(${heroImg});
    min-height: 812px;
    align-items: center;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 1200px) {
    align-items: flex-start;
    max-width: 50%;
    margin-left: 50%;
  }
`;

const Title = styled.h1`
  text-align: center;
  color: #ffffff;
  margin: 0;
  line-height: 1;
  font-size: 26px;
  font-weight: 700;

  @media screen and (min-width: 1200px) {
    font-size: 80px;
    font-weight: 900;
    text-align: left;
  }
`;

const DesktopDescription = styled.span``;

const MobileDescription = styled.span``;

const Description = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 13px;
  color: #ffffff;
  text-align: center;
  margin-top: 10px;
  max-width: 260px;

  br {
    display: none;
  }

  ${DesktopDescription} {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    text-align: right;
    font-size: 15px;
    max-width: initial;
    margin-top: 20px;

    ${DesktopDescription} {
      display: initial;
    }

    ${MobileDescription} {
      display: none;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;

  @media screen and (min-width: 1200px) {
    margin-top: 45px;
    margin-bottom: 0;
  }
`;

const DescriptionBackground = styled.p`
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  position: absolute;
  color: #ffffff;
  bottom: 100px;
  display: none;

  @media screen and (min-width: 1200px) {
    display: initial;
  }
`;

export default () => (
  <Wrapper>
    <Grid>
      <Row>
        <Main>
          <Title>
            Эрмитаж:<br />Погружение в историю
          </Title>

          <Description>
            <DesktopDescription>
              Документально-игровой фильм в формате вирутальной реальности
            </DesktopDescription>

            <MobileDescription>
              Магический опыт путешествия в другую эпоху.
            </MobileDescription>
          </Description>

          <ButtonWrapper>
            <Button
              onClick={() =>
                scroller.scrollTo('buy', {
                  duration: 500,
                  smooth: true
                })
              }
            >
              Купить билет
            </Button>
          </ButtonWrapper>
        </Main>

        <DescriptionBackground>
          Константин Хабенский
          <br />— главный актер фильма
        </DescriptionBackground>
      </Row>
    </Grid>
  </Wrapper>
);
