import React from 'react';
import styled from 'styled-components';
import { Grid } from 'react-flexbox-grid';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';

import { EmptyButton as UIEmptyButton } from '../../UI';

// import circleBackground from './circleBackground.svg';
// import schemaMap from './map@2x.png';
import schemaMap from './map.jpg';
import schemaMapMobile from './mapMobile.jpg';

const mapStyles = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e9e9e9'
      },
      {
        lightness: 17
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5'
      },
      {
        lightness: 20
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 17
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 29
      },
      {
        weight: 0.2
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 18
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 16
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5'
      },
      {
        lightness: 21
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'off'
      },
      {
        color: '#dedede'
      },
      {
        lightness: 21
      }
    ]
  },
  {
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on'
      },
      {
        color: '#ffffff'
      },
      {
        lightness: 16
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: 36
      },
      {
        color: '#333333'
      },
      {
        lightness: 40
      }
    ]
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f2f2'
      },
      {
        lightness: 19
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#fefefe'
      },
      {
        lightness: 20
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#fefefe'
      },
      {
        lightness: 17
      },
      {
        weight: 1.2
      }
    ]
  }
];

const Wrapper = styled.div`
  margin-top: 50px;
  /* background-repeat: no-repeat;
  background-position: 100% 350px; */
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 200%;
    height: 1007px;
    background-color: #fff;
    transform: rotate(-29deg);
    left: -102%;
    bottom: 54%;
  }

  @media screen and (min-width: 1200px) {
    /* background-image: url(circleBackground); */
    padding-top: 140px;
    margin-top: 90px;

    &:before {
      left: -36%;
      bottom: 66%;
    }
  }
`;

const Title = styled.h2`
  margin: 0;
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: 700;
  text-align: center;

  @media screen and (min-width: 1200px) {
    font-size: 50px;
    font-weight: 900;
    text-align: left;
  }
`;

const Description = styled.p`
  margin: 0;
  font-size: 12px;
  padding: 0 15px;

  br {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    padding: 0;
  }
`;

const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  margin-top: 40px;
  justify-content: space-between;
  padding: 0 15px;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    flex-basis: 39%;
    padding: 0;
  }
`;

const Point = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 0;
  }
`;

const PointTitle = styled.h4`
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 5px;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    margin-bottom: 10px;
  }
`;

const PointDescription = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 500;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;

const Titles = styled.div`
  flex-basis: 50%;
`;

const ButtonsWrapper = styled.div`
  flex-basis: 50%;
  display: flex;
  justify-content: flex-end;
`;

const ButtonsWrapperDesktop = styled(ButtonsWrapper)`
  display: none;

  @media screen and (min-width: 1200px) {
    display: flex;
  }
`;

const ButtonsWrapperMobile = styled(ButtonsWrapper)`
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const Buttons = styled.div`
  text-align: center;
`;

const FakeLink = styled.span`
  color: #3797f0;
`;

const EmptyButton = styled(UIEmptyButton)`
  margin-bottom: 10px;
`;

const ContactsWrapper = styled.div`
  display: flex;
`;

const Inner = styled.div`
  /* background-repeat: no-repeat;
  background-size: 296px; */
  position: relative;
  z-index: 2;

  /* @media screen and (min-width: 1200px) {
    background-size: 900px;
    background-position: 89% 120px;
    padding-bottom: 800px;
    background-image: url(schemaMap);
  } */
`;

// const SchemaMapMobileWrapper = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   position: relative;
//   right: -61px;

//   @media screen and (min-width: 1200px) {
//     display: none;
//   }
// `;

// const SchemaMapMobile = styled.img`
//   width: 296px;
//   height: 296px;
// `;

const SchemaMap = styled.div`
  margin-top: 50px;
  margin-bottom: 80px;
  width: 100%;
  position: relative;

  &:after {
    content: '';
    box-sizing: border-box;
    display: flex;
    position: relative;
    height: 100%;
    width: 500px;
    max-width: 97%;
    border: 4px solid #3797f0;
    z-index: -1;
    position: absolute;
    top: 15px;
    left: 15px;

    width: 100%;
    top: 8px;
    left: 19px;
  }

  @media screen and (min-width: 1200px) {
    margin-top: 100px;
    margin-bottom: 90px;

    &:after {
      width: 100%;
      border: 10px solid #3797f0;
      max-width: 100%;
      top: 30px;
      left: 30px;
    }
  }
`;

const DesktopMap = styled.img`
  display: none;
  max-width: 100%;

  @media screen and (min-width: 1200px) {
    display: block;
  }
`;

const MobileMap = styled.img`
  display: block;
  max-width: 96%;

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const YandexMap = styled.iframe`
  width: 100%;
  height: 500px;
  border: none;
`;

const MyMapComponent = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat: 59.938615, lng: 30.317798 }}
      defaultOptions={{ styles: mapStyles }}
    >
      {props.isMarkerShown && (
        <Marker position={{ lat: 59.938615, lng: 30.317798 }} />
      )}
    </GoogleMap>
  ))
);

export default () => (
  <Wrapper id="howtoget">
    <Inner>
      <Grid>
        <Header>
          <Titles>
            <Title>Как добраться</Title>

            <Description>
              VR-кинотеатр расположен в здании Главного Штаба Государственного
              музея Эрмитаж. Ждем вас во вторник, четверг, субботу и воскресенье
              — с 10:30 до 18:00, в среду и пятницу — с 12:00 до 21:00.
              Понедельник — выходной день в музее.
            </Description>
          </Titles>

          <ButtonsWrapperDesktop>
            <Buttons>
              <EmptyButton
                onClick={() =>
                  window.open(
                    'https://yandex.ru/maps/2/saint-petersburg/?ll=30.319061%2C59.938865&mode=routes&rtext=~59.939163%2C30.318069&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D1289678035&z=15.95',
                    '_blank'
                  )
                }
              >
                Построить маршрут
              </EmptyButton>
            </Buttons>
          </ButtonsWrapperDesktop>
        </Header>

        <ContactsWrapper>
          <Contacts>
            <Point>
              <PointTitle>Адрес</PointTitle>

              <PointDescription>
                Санкт-Петербург,
                <br />
                Дворцовая пл., д. 1-2
                <br />
                здание Главного Штаба
              </PointDescription>
            </Point>

            <Point>
              <PointTitle>Контакты</PointTitle>

              <PointDescription>
                +7 (812) 429-74-07
                <br />
                info@hermitageshop.com
              </PointDescription>
            </Point>
          </Contacts>
        </ContactsWrapper>

        <SchemaMap>
          {/* <DesktopMap src={schemaMap} />
          <MobileMap src={schemaMapMobile} /> */}
          {/* <MyMapComponent
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCqgQTiNjXswB4YN4wJHwrbZpolydxGZ_o&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          /> */}
          <YandexMap
            src="https://yandex.ru/map-widget/v1/-/CGD9fK~9"
            allowfullscreen="true"
          />
        </SchemaMap>

        {/* <SchemaMapMobileWrapper>
            <SchemaMapMobile src={schemaMap} />
          </SchemaMapMobileWrapper> */}

        <ButtonsWrapperMobile>
          <Buttons>
            <EmptyButton
              onClick={() =>
                window.open(
                  'https://yandex.ru/maps/2/saint-petersburg/?ll=30.319061%2C59.938865&mode=routes&rtext=~59.939163%2C30.318069&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D1289678035&z=15.95',
                  '_blank'
                )
              }
            >
              Построить маршрут
            </EmptyButton>
          </Buttons>
        </ButtonsWrapperMobile>
      </Grid>
    </Inner>
  </Wrapper>
);
