import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Quantity } from '../../UI';

const Wrapper = styled.div``;

const Point = styled.h4`
  margin: 0;
  font-weight: 500;
  font-size: 10px;
  margin-bottom: 10px;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    margin: 0;
  }
`;

const Name = styled.h3`
  font-size: 26px;
  font-weight: 300;
  margin: 0;
  flex-basis: 45%;

  @media screen and (min-width: 1200px) {
    font-size: 50px;
  }
`;

const QuantityWrapper = styled.div`
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  color: ${({ theme }) => (theme === 'black' ? '#000' : '#FFF')};

  @media screen and (min-width: 1200px) {
    margin-bottom: 0;
  }
`;

const Price = styled.div`
  font-size: 26px;
  font-weight: 300;
  flex-basis: 30%;

  @media screen and (min-width: 1200px) {
    font-size: 50px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 45%;
  margin-bottom: 20px;
  color: ${({ theme }) => (theme === 'black' ? '#000' : '#FFF')};

  @media screen and (min-width: 1200px) {
    margin-bottom: 0;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  color: ${({ theme }) => (theme === 'black' ? '#000' : '#FFF')};
`;

export default function Categories({
  currentNum,
  onChangeNum,
  price,
  from,
  nameTicket,
  isLoadedData
}) {
  const theme = from === 'tickets' ? 'white' : 'black';

  return (
    <Wrapper>
      <Card>
        <NameWrapper theme={theme}>
          <Point>Наименование</Point>
          <Name>{isLoadedData ? nameTicket : ''}</Name>
        </NameWrapper>

        <QuantityWrapper theme={theme}>
          <Point>Количество</Point>

          {isLoadedData && (
            <Quantity
              onChangeNum={newNum => onChangeNum(newNum)}
              currentNum={currentNum}
              isVisible={from === 'tickets'}
              theme={theme}
            />
          )}
        </QuantityWrapper>

        <PriceWrapper theme={theme}>
          <Point>Цена</Point>
          <Price>{isLoadedData ? `${price} руб.` : ''}</Price>
        </PriceWrapper>
      </Card>
    </Wrapper>
  );
}
