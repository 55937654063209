import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  border-radius: 15px;
  background-color: #f7f7f7;
  font-size: 12px;
  font-weight: 500;
  padding: 15px 20px;
  margin-bottom: 20px;
  width: 90%;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    width: auto;
  }
`;

export default (props) => <Wrapper>{props.children}</Wrapper>;
