import React from 'react';
import styled from 'styled-components';
import { Grid } from 'react-flexbox-grid';

import Header from '../Header';
import Footer from '../Footer';

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 100px;

  @media screen and (min-width: 1200px) {
    margin-top: 50px;
    margin-bottom: 150px;
  }
`;

const Title = styled.h1`
  margin: 0;
  margin-bottom: 30px;
  font-size: 23px;

  @media screen and (min-width: 1200px) {
    margin-bottom: 50px;
    font-size: 30px;
  }
`;

const Content = styled.div`
  font-size: 13px;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }
`;

export default () => (
  <>
    <Header />

    <Wrapper>
      <Grid>
        <Title>О нас</Title>

        <Content>
          При помощи новейших технологий, объединивших виртуальную реальность и
          кино, фильм в формате виртуальной реальности (Virtual Reality) создает
          иллюзию присутствия зрителя в реальном пространстве и позволяет ему
          стать участником событий с помощью специальных очков.
          <br />
          <br />
          В основу сценария легли значимые события основных периодов истории
          Эрмитажа со времен Екатерины Великой до наших дней, рассказывая о
          трансформации из закрытого для широкой публики института в один из
          самых посещаемых музеев Европы.
          <br />
          <br />
          Дворцовая площадь, залы музея, Висячий сад и крыша Зимнего дворца
          открываются по-новому в сопровождении «экскурсовода- мистика»,
          обладающего даром перемещения во времени и пространстве. Его роль
          исполнил один из самых известных российских актеров театра и кино
          Константин Хабенский.
          <br />
          <br />
          «Ты хочешь попасть в Эрмитаж? Вместе со мной ты можешь не только зайти
          туда, но даже оказаться в прошлом и узнать его историю. <br />
          <br />
          Перед нами не только музей, где собраны произведения искусства, но и
          императорская резиденция – дом русских монархов», ‒ говорит
          экскурсовод в начале фильма, приглашая совершить виртуальную прогулку.
          Вместе с ним зритель окажется в залах Эрмитажа, переместится из одной
          эпохи в другую, побывает в местах, скрытых от глаз обычного
          посетителя.
          <br />
          <br />
          Известный петербургский дизайнер Янис Чамалиди участвовал в создании
          костюмов персонажей.
          <br />
          <br />
          Неоклассический российский композитор Михаил Мищенко стал автором
          музыки к фильму.
          <br />
          <br />
          Проект носит образовательно-художественный характер. Его главной целью
          является прочтение истории музея в нестандартном формате посредством
          использования новейших технологий.
          <br />
          Продолжительность просмотра составляет 19 минут.
          <br />
          <br />
          Участники проекта: Компания «Артеком» совместно со студиями Super8.pro
          и Videofabrika.ru для Государственного Музея Эрмитаж.
          <br />
          <br />
          Научный консультант: Татьяна Пашкова, ведущий научный сотрудник
          <br />
          Государственного Эрмитажа, кандидат искусствоведения.
          <br />
          Режиссер фильма: Михаил Антыков.
          <br />
          Креативный продюсер проекта: Сергей Захаров, Борис Пиотровский, Виктор
          Гаскельберг.
          <br />
          Автор костюма главного героя: Янис Чамалиди.
          <br />
          Автор музыки ‒ Михаил Мищенко.
          <br />
          <br />
          Стоимость билета - 350 рублей
          <br />
          <br />
          График работы: <br />
          Вторник, четверг, суббота и воскресенье - с 10:30 до 18:00, среда и
          <br />
          пятница с 12:00 до 21:00.
          <br />
          (понедельник - выходной день в Государственном Эрмитаже)
          <br />
          <br />
          Для детей старше 8 лет.
        </Content>
      </Grid>
    </Wrapper>

    <Footer />
  </>
);
