import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Grid, Row } from 'react-flexbox-grid';
import axios from 'axios';

import VRLogo from '../ticketsVRLogo.svg';
import circle from './circle.svg';

import Categories from './Categories';
import Actions from './Actions';
import Modal from './Modal';

const Wrapper = styled.div`
  margin-top: 20px;

  @media screen and (min-width: 1200px) {
    margin-top: 100px;
  }
`;

const Panel = styled.div`
  background-color: #3797f0;
  border-radius: 20px;
  box-shadow: 15px 15px 40px 0 rgba(55, 151, 240, 0.44);
  width: 90%;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: 105% -9%;

  @media screen and (min-width: 1200px) {
    width: 100%;
    border-radius: 51px;
    background-image: url(${circle});
  }
`;

const Title = styled.h2`
  font-size: 50px;
  font-weight: 900;
  color: #ffffff;
  margin: 0;
  flex-basis: 50%;
  display: none;

  @media screen and (min-width: 1200px) {
    display: initial;
  }
`;

const PanelInner = styled.div`
  padding: 30px 40px;

  @media screen and (min-width: 1200px) {
    padding: 70px 100px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 58px;

  @media screen and (min-width: 1200px) {
    margin-bottom: 165px;
  }
`;

const DescriptionWrapper = styled.div`
  flex-basis: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 1200px) {
    flex-basis: 50%;
  }
`;

const Description = styled.p`
  font-size: 10px;
  font-weight: 500;
  margin: 0;
  color: #fff;
  text-align: center;
  width: 100%;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    text-align: left;
    width: auto;
  }
`;

const Logo = styled.img`
  width: 54px;
  display: none;

  @media screen and (min-width: 1200px) {
    display: initial;
  }
`;

let initialPrice = 350;
const initialQuantity = 1;
const initialName = 'VR-фильм (6+)';

export default function Tickets() {
  const [currentNum, setNum] = useState(initialQuantity);
  const [price, setPrice] = useState(initialPrice);
  const [isReadPolicy, setReadPolicy] = useState(false);
  const [isReceiveNews, setReceiveNews] = useState(false);
  const [isSending, setSending] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [isLoadedData, setLoadedData] = useState(false);

  const [nameTicket, setNameTicket] = useState(initialName);
  const [currencyID, setCurrencyID] = useState(0);
  const [article, setArticle] = useState('');
  const [id, setID] = useState('');

  useEffect(() => setPrice(currentNum * initialPrice), [currentNum]);

  useEffect(() => {
    axios.get(`https://www.hermitageshop.ru/api/v2/vr`).then(({ data }) => {
      const vrTicket = data[0].vr;
      console.log(vrTicket, 'vr ticket');
      setPrice(parseInt(vrTicket.price, 10));
      initialPrice = vrTicket.price;
      setNameTicket(vrTicket.name);
      setCurrencyID(vrTicket.currency_id);
      setArticle(vrTicket.article);
      setID(parseInt(vrTicket.id, 10));
      setLoadedData(true);
    });
  }, []);

  useEffect(() => {
    if (isSending) {
      if (!isReadPolicy) {
        alert('Вам нужно прочесть условия Политики Конфиденциальности');
        setSending(false);
      } else {
        // alert(`Покупаю: ${currentNum} билетов за ${price} руб.`);
        setSending(false);
        setOpenModal(true);
      }
    }
  }, [isSending]);

  return (
    <>
      <Wrapper id="buy">
        <Grid>
          <Row>
            <Panel>
              <PanelInner>
                <Header>
                  <Title>Купить билет</Title>

                  <DescriptionWrapper>
                    <Description>
                      Электронный билет дает право
                      <br />
                      посетить мероприятие вне очереди
                    </Description>

                    <Logo src={VRLogo} />
                  </DescriptionWrapper>
                </Header>

                <Categories
                  currentNum={currentNum}
                  onChangeNum={newNum => setNum(newNum)}
                  price={price}
                  isLoadedData={isLoadedData}
                  nameTicket={nameTicket}
                  from="tickets"
                />

                <Actions
                  isReadPolicy={isReadPolicy}
                  setReadPolicy={setReadPolicy}
                  isReceiveNews={isReceiveNews}
                  setReceiveNews={setReceiveNews}
                  isSending={isSending}
                  setSending={setSending}
                  isLoadedData={isLoadedData}
                />
              </PanelInner>
            </Panel>
          </Row>
        </Grid>
      </Wrapper>

      <Modal
        isLoadedData={isLoadedData}
        isOpen={isOpenModal}
        currentNum={currentNum}
        price={price}
        nameTicket={nameTicket}
        currencyID={currencyID}
        article={article}
        id={id}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
}
