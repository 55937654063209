import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { scroller } from 'react-scroll';

import { EmptyButton as UIEmptyButton } from '../../UI';

import background from './background.svg';
import backgroundMobile from './backgroundMobile.svg';

import Slider from './Slider';

import ModalTrailer from '../../Modals/Trailer';

const Wrapper = styled.div`
  background-image: url(${backgroundMobile});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 100% 132px;
  padding-top: 50px;
  padding-bottom: 40px;

  @media screen and (min-width: 1200px) {
    padding-top: 175px;
    padding-bottom: 125px;
    background-image: url(${background});
    background-position: 0 100px;
  }
`;

const Title = styled.h2`
  color: #000000;
  font-size: 26px;
  font-weight: 700;
  margin: 0;
  text-align: center;

  @media screen and (min-width: 1200px) {
    font-size: 50px;
    font-weight: 900;
    text-align: left;
  }
`;

const InformationWrapper = styled.div`
  padding-top: 30px;
`;

const Description = styled.p`
  margin: 0;
  margin-top: 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.7;
  padding: 0 15px;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    margin-top: 50px;
    padding: 0;
  }
`;

const Information = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;

const Point = styled.div`
  margin-bottom: 20px;

  @media screen and (min-width: 1200px) {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const InfoTitle = styled.h4`
  font-size: 12px;
  font-weight: 700;
  color: #000000;
  margin: 0;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }
`;

const InfoDescription = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  color: #000000;
  margin-top: 5px;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }
`;

const Points = styled.div`
  flex-basis: 50%;
  padding: 0 15px;

  @media screen and (min-width: 1200px) {
    padding: 0;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;

  @media screen and (min-width: 1200px) {
    margin-top: 40px;
  }
`;

const EmptyButton = styled(UIEmptyButton)`
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    margin: initial;
  }
`;

const AboutFilm = () => {
  const [isOpenTrailer, setOpenTrailer] = useState(false);

  return (
    <>
      <Wrapper id="aboutfilm">
        <Grid>
          <Row>
            <Col xs={12} xl={4}>
              <InformationWrapper>
                <Title>О фильме</Title>

                <Description>
                  Совместите экскурс в историю Эрмитажа с незабываемым
                  приключением в формате виртуальной реальности! Сценарий фильма
                  основан на важнейших событиях в истории Эрмитажа со времен
                  Екатерины Великой до наших дней. Привычные интерьеры дворца
                  открываются совершенно в новом свете в сопровождении
                  «экскурсовода-мистика», способного перемещаться во времени и
                  пространстве.
                </Description>

                <Information>
                  <Points>
                    <Point>
                      <InfoTitle>Продолжительность</InfoTitle>
                      <InfoDescription>19 минут</InfoDescription>
                    </Point>

                    <Point>
                      <InfoTitle>Язык</InfoTitle>
                      <InfoDescription>
                        русский, английский (субтитры)
                      </InfoDescription>
                    </Point>
                  </Points>

                  <Points>
                    <Point>
                      <InfoTitle>Режиссер</InfoTitle>
                      <InfoDescription>Михаил Антыков</InfoDescription>
                    </Point>

                    <Point>
                      <InfoTitle>Главные роли</InfoTitle>
                      <InfoDescription>
                        Константин Хабенский
                        <br />
                        Михаил Пиотровский
                      </InfoDescription>
                    </Point>
                  </Points>
                </Information>

                <ButtonWrapper>
                  <EmptyButton
                    // onClick={() =>
                    //   scroller.scrollTo('film', {
                    //     duration: 500,
                    //     smooth: true
                    //   })
                    // }
                    onClick={() => setOpenTrailer(true)}
                  >
                    Посмотреть трейлер
                  </EmptyButton>
                </ButtonWrapper>
              </InformationWrapper>
            </Col>

            <Col xs={12} xl={8}>
              <Slider />
            </Col>
          </Row>
        </Grid>
      </Wrapper>

      <ModalTrailer
        isOpen={isOpenTrailer}
        onClose={() => setOpenTrailer(false)}
      />
    </>
  );
};

export default AboutFilm;
