import React from 'react';
import styled from 'styled-components/macro';

import { Radiobutton } from '../../UI';

import mastercard from './cards/mastercard.png';
import visa from './cards/visa.png';
import mir from './cards/mir.png';
import webmoney from './cards/webmoney.png';

const Payment = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  padding: 15px;
  margin-bottom: 30px;

  @media screen and (min-width: 1200px) {
    padding: 30px;
  }
`;

const Info = styled.div`
  color: #656565;
  font-size: 10px;
  font-weight: 500;
  margin-top: 10px;

  @media screen and (min-width: 1200px) {
    padding-left: 38px;
    font-size: 12px;
  }
`;

const Cards = styled.div`
  display: flex;
  align-items: center;
  margin-top: 13px;
  justify-content: space-between;

  @media screen and (min-width: 1200px) {
    justify-content: flex-start;
    margin-top: 10px;
  }
`;

const Card = styled.div`
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
`;

const CardLogo = styled.img`
  max-width: ${props => props.width};
`;

const Title = styled.h4`
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 20px;
`;

export default () => (
  <Payment>
    <Title>Способ оплаты</Title>

    <div>
      <Radiobutton isActive onClick={() => {}}>
        Платежная система PayU
      </Radiobutton>
    </div>

    <Info>
      Мы принимаем к оплате (через платежную систему
      <br />
      PayU) банковские карты и электронные деньги:
      <Cards>
        <Card>
          <CardLogo src={mastercard} width="26px" />
        </Card>
        <Card>
          <CardLogo src={visa} width="43px" />
        </Card>
        <Card>
          <CardLogo src={mir} width="50px" />
        </Card>
        <Card>
          <CardLogo src={webmoney} width="20px" />
        </Card>
      </Cards>
    </Info>
  </Payment>
);
