import React from 'react';
import styled from 'styled-components';

import { Button } from '../UI';

const Wrapper = styled.div`
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const Layout = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(59, 59, 59, 0.62);
`;

const Content = styled.div`
  width: 70%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  max-height: 80%;
  overflow: auto;

  @media screen and (min-width: 1200px) {
    padding: 60px 100px;
  }
`;

const Title = styled.h2`
  font-size: 17px;
  font-weight: 700;
  margin: 0;
  text-align: center;

  @media screen and (min-width: 1200px) {
    font-size: 31px;
    text-align: left;
  }
`;

const SubTitle = styled.h3`
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;

  @media screen and (min-width: 1200px) {
    font-size: 17px;
    text-align: left;
    margin: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Text = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;
  word-break: break-word;
`;

const BrandColor = styled.span`
  color: #e71d85;
`;

export default ({ isOpen, onClose }) => (
  <Wrapper isOpen={isOpen}>
    <Layout onClick={onClose} />

    <Content>
      <Title>Политика конфиденциальность</Title>
      <SubTitle>Ознакомьтесь с правилами приобритения</SubTitle>

      <Text>
        <p>
          <BrandColor>
            <strong>1. Общие положения</strong>
          </BrandColor>
          <br />
          <br />
          Настоящая политика обработки персональных данных составлена в
          соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ
          &laquo;О персональных данных&raquo; и определяет порядок обработки
          персональных данных и меры по обеспечению безопасности персональных
          данных&nbsp;ООО Артеком&nbsp;(далее &ndash; Оператор).
          <br />
          <br />
          Оператор ставит своей важнейшей целью и условием осуществления своей
          деятельности соблюдение прав и свобод человека и гражданина при
          обработке его персональных данных, в том числе защиты прав на
          неприкосновенность частной жизни, личную и семейную тайну.
          <br />
          Настоящая политика Оператора в отношении обработки персональных данных
          (далее &ndash; Политика) применяется ко всей информации, которую
          Оператор может получить о посетителях веб-сайта&nbsp;
          <a style={{ color: '#e71d85' }} href="https://vr.hermitageshop.ru">
            https://vr.hermitageshop.ru
          </a>
          .
        </p>
        <p>
          <br />
          <BrandColor>
            <strong>2. Основные понятия, используемые в Политике</strong>
          </BrandColor>
          <br />
          <br />
          Автоматизированная обработка персональных данных &ndash; обработка
          персональных данных с помощью средств вычислительной техники;
          <br />
          Блокирование персональных данных &ndash; временное прекращение
          обработки персональных данных (за исключением случаев, если обработка
          необходима для уточнения персональных данных);
        </p>
        <p>
          <strong>Веб-сайт</strong> &ndash; совокупность графических и
          информационных материалов, а также программ для ЭВМ и баз данных,
          обеспечивающих их доступность в сети интернет по сетевому адресу&nbsp;
          <a style={{ color: '#e71d85' }} href="https://vr.hermitageshop.ru">
            https://vr.hermitageshop.ru
          </a>
        </p>
        <p>
          <br />
          <strong>Информационная система персональных данных</strong> &mdash;
          совокупность содержащихся в базах данных персональных данных, и
          обеспечивающих их обработку информационных технологий и технических
          средств;
        </p>
        <p>
          <br />
          <strong>Обезличивание персональных данных</strong> &mdash; действия, в
          результате которых невозможно определить без использования
          дополнительной информации принадлежность персональных данных
          конкретному Пользователю или иному субъекту персональных данных;
          <br />
          Обработка персональных данных &ndash; любое действие (операция) или
          совокупность действий (операций), совершаемых с использованием средств
          автоматизации или без использования таких средств с персональными
          данными, включая сбор, запись, систематизацию, накопление, хранение,
          уточнение (обновление, изменение), извлечение, использование, передачу
          (распространение, предоставление, доступ), обезличивание,
          блокирование, удаление, уничтожение персональных данных;
        </p>
        <p>
          <br />
          <strong>Оператор</strong> &ndash; государственный орган, муниципальный
          орган, юридическое или физическое лицо, самостоятельно или совместно с
          другими лицами организующие и (или) осуществляющие обработку
          персональных данных, а также определяющие цели обработки персональных
          данных, состав персональных данных, подлежащих обработке, действия
          (операции), совершаемые с персональными данными;
          <br />
          Персональные данные &ndash; любая информация, относящаяся прямо или
          косвенно к определенному или определяемому Пользователю
          веб-сайта&nbsp;
          <a style={{ color: '#e71d85' }} href="https://vr.hermitageshop.ru">
            https://vr.hermitageshop.ru
          </a>
        </p>
        <p>
          <br />
          <strong>Пользователь</strong> &ndash; любой посетитель веб-сайта&nbsp;
          <a style={{ color: '#e71d85' }} href="https://vr.hermitageshop.ru">
            https://vr.hermitageshop.ru
          </a>
        </p>
        <p>
          <br />
          <strong>Предоставление персональных данных</strong> &ndash; действия,
          направленные на раскрытие персональных данных определенному лицу или
          определенному кругу лиц;
        </p>
        <p>
          <br />
          <strong>Распространение персональных данных</strong> &ndash; любые
          действия, направленные на раскрытие персональных данных
          неопределенному кругу лиц (передача персональных данных) или на
          ознакомление с персональными данными неограниченного круга лиц, в том
          числе обнародование персональных данных в средствах массовой
          информации, размещение в информационно-телекоммуникационных сетях или
          предоставление доступа к персональным данным каким-либо иным способом;
        </p>
        <p>
          <br />
          <strong>Трансграничная передача персональных данных</strong> &ndash;
          передача персональных данных на территорию иностранного государства
          органу власти иностранного государства, иностранному физическому или
          иностранному юридическому лицу;
        </p>
        <p>
          <br />
          <strong>Уничтожение персональных данных</strong> &ndash; любые
          действия, в результате которых персональные данные уничтожаются
          безвозвратно с невозможностью дальнейшего восстановления содержания
          персональных данных в информационной системе персональных данных и
          (или) результате которых уничтожаются материальные носители
          персональных данных.
        </p>
        <p>&nbsp;</p>
        <p>
          <BrandColor>
            <strong>
              3. Оператор может обрабатывать следующие персональные данные
              Пользователя
            </strong>
          </BrandColor>
          <br />
          <br />
          Фамилия, имя, отчество;
          <br />
          Электронный адрес;
          <br />
          Номера телефонов;
          <br />
          Год, месяц, дата и место рождения;
        </p>
        <p>
          Также на сайте происходит сбор и обработка обезличенных данных о
          посетителях (в т.ч. файлов &laquo;cookie&raquo;) с помощью сервисов
          интернет-статистики (Яндекс Метрика, Гугл Аналитика и др.).
        </p>
        <p>
          Вышеперечисленные данные далее по тексту Политики объединены общим
          понятием Персональные данные.
        </p>
        <p>
          <br />
          <br />
          <strong>
            <BrandColor>&nbsp;4. Цели обработки персональных данных</BrandColor>
          </strong>
          <br />
          <br />
          Цель обработки персональных данных Пользователя
          &mdash;&nbsp;информирование Пользователя посредством отправки
          электронных писем; заключение, исполнение и прекращение
          гражданско-правовых договоров; предоставление доступа Пользователю к
          сервисам, информации и/или материалам, содержащимся на веб-сайте.
        </p>
        <p>
          Также Оператор имеет право направлять Пользователю уведомления о новых
          продуктах и услугах, специальных предложениях и различных событиях.
          Пользователь всегда может отказаться от получения информационных
          сообщений, направив Оператору письмо на адрес электронной
          почты&nbsp;info@hermitageshop.com&nbsp;с пометкой &laquo;Отказ от
          уведомлениях о новых продуктах и услугах и специальных
          предложениях&raquo;.
        </p>
        <p>
          Обезличенные данные Пользователей, собираемые с помощью сервисов
          интернет-статистики, служат для сбора информации о действиях
          Пользователей на сайте, улучшения качества сайта и его содержания.
        </p>
        <p>
          <br />
          <BrandColor>
            <strong>5. Правовые основания обработки персональных данных</strong>
          </BrandColor>
          <br />
          <br />
          Оператор обрабатывает персональные данные Пользователя только в случае
          их заполнения и/или отправки Пользователем самостоятельно через
          специальные формы, расположенные на
          сайте&nbsp;https://vr.hermitageshop.ru. Заполняя соответствующие формы
          и/или отправляя свои персональные данные Оператору, Пользователь
          выражает свое согласие с данной Политикой.
        </p>
        <p>
          Оператор обрабатывает обезличенные данные о Пользователе в случае,
          если это разрешено в настройках браузера Пользователя (включено
          сохранение файлов &laquo;cookie&raquo; и использование технологии
          JavaScript).
        </p>
        <p>
          <br />
          <br />
          <BrandColor>
            <strong>
              6. Порядок сбора, хранения, передачи и других видов обработки
              персональных данных
            </strong>
          </BrandColor>
          <br />
          <br />
          Безопасность персональных данных, которые обрабатываются Оператором,
          обеспечивается путем реализации правовых, организационных и
          технических мер, необходимых для выполнения в полном объеме требований
          действующего законодательства в области защиты персональных данных.
          <br />
          <br />
          Оператор обеспечивает сохранность персональных данных и принимает все
          возможные меры, исключающие доступ к персональным данным
          неуполномоченных лиц.
        </p>
        <p>
          Персональные данные Пользователя никогда, ни при каких условиях не
          будут переданы третьим лицам, за исключением случаев, связанных с
          исполнением действующего законодательства.
        </p>
        <p>
          В случае выявления неточностей в персональных данных, Пользователь
          может актуализировать их самостоятельно, путем направления Оператору
          уведомление на адрес электронной почты
          Оператора&nbsp;info@hermitageshop.com&nbsp;с пометкой
          &laquo;Актуализация персональных данных&raquo;.
        </p>
        <p>
          Срок обработки персональных данных является неограниченным.
          Пользователь может в любой момент отозвать свое согласие на обработку
          персональных данных, направив Оператору уведомление посредством
          электронной почты на электронный адрес Оператора
          info@hermitageshop.com&nbsp;с пометкой &laquo;Отзыв согласия на
          обработку персональных данных&raquo;.
        </p>
        <p>
          <br />
          <strong>
            <BrandColor>
              7. Трансграничная передача персональных данных
            </BrandColor>
          </strong>
          <br />
          <br />
          Оператор до начала осуществления трансграничной передачи персональных
          данных обязан убедиться в том, что иностранным государством, на
          территорию которого предполагается осуществлять передачу персональных
          данных, обеспечивается надежная защита прав субъектов персональных
          данных.
        </p>
        <p>
          Трансграничная передача персональных данных на территории иностранных
          государств, не отвечающих вышеуказанным требованиям, может
          осуществляться только в случае наличия согласия в письменной форме
          субъекта персональных данных на трансграничную передачу его
          персональных данных и/или исполнения договора, стороной которого
          является субъект персональных данных.
        </p>
        <p>
          <br />
          <strong>
            <BrandColor>8. Заключительные положения</BrandColor>
          </strong>
          <br />
          <br />
          Пользователь может получить любые разъяснения по интересующим
          вопросам, касающимся обработки его персональных данных, обратившись к
          Оператору с помощью электронной почты&nbsp;
          <strong>info@hermitageshop.com.</strong>
        </p>
        <p>
          В данном документе будут отражены любые изменения политики обработки
          персональных данных Оператором. Политика действует бессрочно до замены
          ее новой версией.
        </p>
        <p>
          Актуальная версия Политики в свободном доступе расположена в сети
          Интернет по адресу&nbsp;
          <a style={{ color: '#e71d85' }} href="https://vr.hermitageshop.ru">
            https://vr.hermitageshop.ru
          </a>
        </p>
      </Text>

      <ButtonWrapper>
        <Button onClick={onClose}>Всё понятно</Button>
      </ButtonWrapper>
    </Content>
  </Wrapper>
);
