import React, { useState, useEffect } from 'react';
import { Grid } from 'react-flexbox-grid';
import styled from 'styled-components';

import Categories from './Categories';
import Form from './Form';

const Layout = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(59, 59, 59, 0.62);
`;

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  background: #ffffff;
  transform: translate(-50%, -50%);
  border-radius: 19px;
  max-height: 90%;
  max-width: 90%;
  overflow: auto;

  @media screen and (min-width: 1200px) {
    border-radius: 51px;
  }
`;

const Title = styled.h2`
  color: #000000;
  font-size: 26px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 30px;
  text-align: center;
  padding-top: 15px;

  @media screen and (min-width: 1200px) {
    font-size: 50px;
    font-weight: 900;
    text-align: left;
    padding: 0;
    margin-bottom: 50px;
  }
`;

const Content = styled.div`
  padding: 0 22px;

  @media screen and (min-width: 1200px) {
    padding: 70px 100px;
  }
`;

const PreWrapper = styled.div`
  position: fixed;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')}
  z-index: 100;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
`;

export default function Modal(props) {
  const {
    currentNum,
    price,
    onClose,
    isOpen,
    nameTicket,
    currencyID,
    article,
    id,
    isLoadedData
  } = props;

  return (
    <PreWrapper isOpen={isOpen}>
      <Layout onClick={onClose} />

      <Wrapper>
        <Grid>
          <Content>
            <Title>Детали заказа</Title>
            <Categories
              from="modal"
              currentNum={currentNum}
              price={price}
              nameTicket={nameTicket}
              isLoadedData={isLoadedData}
            />

            <Form
              currentNum={currentNum}
              price={price}
              currencyID={currencyID}
              article={article}
              id={id}
            />
          </Content>
        </Grid>
      </Wrapper>
    </PreWrapper>
  );
}
