import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Grid, Row } from 'react-flexbox-grid';
import { Link as RLink } from 'react-router-dom';

import { HashLink as BaseScrollLink } from 'react-router-hash-link';

import logoSVG from './logo.svg';
import miniLogoSVG from './minilogo.svg';
import burger from './burger.svg';

const Wrapper = styled.header``;

const Desktop = styled.div`
  background-color: #fafafa;
`;

const HeaderInner = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px 0;

  @media screen and (min-width: 1200px) {
    padding: 0;
  }
`;

const Logo = styled.img`
  max-width: 100%;
`;

const LogoWrapper = styled.div`
  flex-basis: 38%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 1200px) {
    flex-basis: 20%;
  }
`;

const Links = styled.div`
  display: none;
  flex-basis: 40%;

  @media screen and (min-width: 1200px) {
    display: flex;
  }
`;

const LinksLeft = styled(Links)``;

const LinksRight = styled(Links)`
  justify-content: flex-end;
`;

const Point = styled.div`
  margin-right: 40px;
  width: 100%;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (min-width: 1200px) {
    width: auto;
  }
`;

const LinkTitle = styled.span`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #3797f0;
    opacity: 0;
    transition: all 0.18s;
  }
`;

const ScrollLink = styled(BaseScrollLink)`
  color: #141414;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  padding: 15px 30px;
  display: block;

  @media screen and (min-width: 1200px) {
    padding: 26px 0;

    &:hover {
      ${LinkTitle} {
        &:after {
          opacity: 1;
        }
      }
    }
  }
`;

const Link = styled.a`
  color: #141414;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  padding: 15px 30px;
  display: block;

  @media screen and (min-width: 1200px) {
    padding: 26px 0;

    &:hover {
      ${LinkTitle} {
        &:after {
          opacity: 1;
        }
      }
    }
  }
`;

const MiniLogoWrapper = styled.div`
  flex-basis: 31%;
  margin-left: 20px;

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const MiniLogo = styled.img``;

const BurgerWrapper = styled.div`
  flex-basis: 31%;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const BurgerIcon = styled.img``;

const MobileWrapper = styled.div`
  position: fixed;
  background-color: #fafafa;
  z-index: 100;
  right: -100%;
  top: 0;
  width: 90%;
  height: 100%;
  padding-top: 20px;
  transform: translate(0, 0);
  transform: ${({ isOpen }) =>
    isOpen ? 'translate(-100%, 0)' : 'translate(0, 0)'};
  transition: transform 0.5s ease;
`;

const List = styled.div``;

const MobileLayout = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: ${({ isOpen }) => (isOpen ? 99 : -1)};
  background-color: #000;
  opacity: ${({ isOpen }) => (isOpen ? 0.5 : 0)};
  transition: all 0.5s ease;
`;

const RouterLink = styled(RLink)``;

function getLeftPoints() {
  return (
    <>
      <Point>
        <ScrollLink to="/#aboutfilm" smooth>
          <LinkTitle>О фильме</LinkTitle>
        </ScrollLink>
      </Point>

      <Point>
        <ScrollLink to="/#howtoget" smooth>
          <LinkTitle>Как добраться</LinkTitle>
        </ScrollLink>
      </Point>

      <Point>
        <ScrollLink to="/#buy" smooth>
          <LinkTitle>Купить билеты</LinkTitle>
        </ScrollLink>
      </Point>
    </>
  );
}

function getRightPoints() {
  return (
    <>
      <Point>
        <Link href="https://www.hermitageshop.ru/" target="_blank">
          <LinkTitle>Эрмитажный магазин</LinkTitle>
        </Link>
      </Point>

      <Point>
        <Link href="https://www.hermitageshop.ru/tickets" target="_blank">
          <LinkTitle>Посещение музея</LinkTitle>
        </Link>
      </Point>
    </>
  );
}

let mobileWrapper = null;

function onClickBody(e, setCloseMenu) {
  const { target } = e;

  if (target !== mobileWrapper) {
    document.body.style.overflow = 'auto';
    setCloseMenu();
  }
}

export default function Header() {
  const [isOpenMobile, setOpenMobile] = useState(false);

  function onClickBodyBootstrap(e) {
    onClickBody(e, () => setOpenMobile(false));
  }

  useEffect(() => {
    if (isOpenMobile) {
      document.body.style.overflow = 'hidden';
      document.body.addEventListener('click', onClickBodyBootstrap);

      return () =>
        document.body.removeEventListener('click', onClickBodyBootstrap);
    }
  }, [isOpenMobile]);

  return (
    <>
      <Wrapper>
        <Desktop>
          <Grid>
            <Row>
              <HeaderInner>
                <MiniLogoWrapper>
                  <RouterLink to="/" href="/">
                    <MiniLogo src={miniLogoSVG} />
                  </RouterLink>
                </MiniLogoWrapper>

                <LinksLeft>{getLeftPoints()}</LinksLeft>

                <LogoWrapper>
                  <RouterLink to="/" href="/">
                    <Logo src={logoSVG} />
                  </RouterLink>
                </LogoWrapper>

                <LinksRight>{getRightPoints()}</LinksRight>

                <BurgerWrapper>
                  <BurgerIcon
                    src={burger}
                    onClick={() => setOpenMobile(!isOpenMobile)}
                  />
                </BurgerWrapper>
              </HeaderInner>
            </Row>
          </Grid>
        </Desktop>

        <MobileWrapper isOpen={isOpenMobile} ref={el => (mobileWrapper = el)}>
          <List>
            {getLeftPoints()}
            {getRightPoints()}
          </List>
        </MobileWrapper>
      </Wrapper>

      <MobileLayout isOpen={isOpenMobile} />
    </>
  );
}
