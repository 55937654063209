import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const Layout = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(59, 59, 59, 0.62);
`;

const Content = styled.div`
  width: 80%;
  height: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  /* padding: 60px 100px; */
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  margin-bottom: -4px;
  border: none;
`;

export default ({ isOpen, onClose }) => (
  <Wrapper isOpen={isOpen}>
    <Layout onClick={onClose} />

    <Content>
      <Iframe
        src="https://www.youtube.com/embed/-GwQBRTgvEs"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </Content>
  </Wrapper>
);
